import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Brueck: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Brueck,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Brück"
          d="M879,550l0.7-0.2l0.5,1.5l-2,1.6l-0.5-0.2c-0.5,0.5-1.6,1.4-1.8,2.1c0.3,0.6,1.3,2,1.7,2.6 c0.7,0.1,1.9-0.1,2.5-0.4c6.1,5.2,13.8,11.7,22.2,11.9c0.1,0.1-1.5,5-1.6,5.3c0.9,0.4,2.4,0.9,2.8,1.9c0,0,0.3,0.2,0.3,0.2l5.7,2.4 l0.7,3.3l-3.8,0.9l-3.4-2.6c0.4,3.9,2.6,8.2,4.4,11.7l7.5,2.2h0.4c6.7,6.5,16.3,14.5,23.6,20.7l2.5-0.1l1.4,0.8 c0.1,0.5-0.5,1.3-0.8,1.6c0.1,0.3,0.4,0.9,0.6,1.2c0.5-0.2,1.7-0.6,2.2-0.9L933,636h-14l-10,16l-10,2l-4,6l-16,4l-18,16l-24-28 l-4-12l-8-6v-16h-8l-14-18l-2-26l18-14l10,14L879,550z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 853.181 615.0728)"
        >
          Brück
        </text>
      </g>
    </>
  );
});
